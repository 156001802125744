.full-screen-image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(247, 243, 248, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 13;
    transition: opacity 0.3s ease-in-out;
    user-select: none;
}

.full-screen-image-container.open {
    opacity: 1;
    pointer-events: auto;
}

.full-screen-image-container.close {
    opacity: 0;
    pointer-events: none;
}

.full-screen-image {
    max-width: 90%;
    max-height: 90%;
}

.close-btn,
.nav-btn {
    position: absolute;
    background-color: transparent;
    border: none;
    color: rgb(0, 0, 0);
    font-size: 2rem;
    cursor: pointer;
    z-index: 15;
    padding: 0.5rem;
    transition: color 0.2s ease-in-out, transform 0.1s ease-in-out;
}

.close{
    display: none;
  }

.nav-btn:hover {
    color: #850F8D;
    transform: scale(1.5);
}

.close-btn:hover {
    color: #cf2f2f;
    transform: scale(1.5);
}

.close-btn {
    top: 10px;
    right: 10px;
}

.prev-btn {
    left: 10px;
}

.next-btn {
    right: 10px;
}

.full-screen-video-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(247, 243, 248, 0.8);
    display: flex;
    z-index: 13;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
  
  .full-screen-video {
    max-width: 90%;
    max-height: 90%;
  }

  .full-screen-video-container.open {
    opacity: 1;
    pointer-events: auto;
}

.full-screen-video-container.close {
    opacity: 0;
    pointer-events: none;
}