.upload-mainBody {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    background: #F7F3F8;
}

.upload-wrapper {
    width: 430px;
    padding: 30px;
    background: #F7F3F8;
    border-radius: 5px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    margin-bottom: 44px;
}

.upload-wrapper:hover {
    box-shadow: 0 2px 8px 4px rgba(133, 15, 141, 0.4);
}

.upload-wrapper header {
    font-size: 27px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.upload-wrapper .upload-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #850F8D;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 10px;
    text-align: center;
}

.upload-form i {
    font-size: 50px;
    transition: color 0.3s ease;
}

.upload-form i:hover{
    color: #850F8D;
}

.upload-form p {
    font-size: 16px;
    margin-top: 10px;
    color: #000000;
}

.upload-form label {
   cursor: pointer;
}

.upload-progress-area {
    margin-top: 10px;
}

.upload-button {
    background-color: #850F8D;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    text-align: center;
}

.upload-no-files-count {
    color : #850F8D;
}

.upload-filename-text {
    margin-top: 10px;
    /* display: inline-block; */
    min-width: 100px;
    max-width: 275px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.failed-uploads-container {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;
  }
  
.failed-uploads-container::-webkit-scrollbar {
    width: 10px;
}

.failed-uploads-container::-webkit-scrollbar-thumb {
    background-color: #850F8D;
    border-radius: 5px;
  }
    
.failed-file-upload {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    background-color: #cf2f2f !important;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 5px;
}

.failed-upload-file-name {
    word-break: break-word;
    padding-right: 15px;
}

@media (max-width: 450px) {
    .upload-wrapper {
        width: 100%;
        padding: 30px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
}