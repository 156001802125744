.delete-main {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
}

.delete-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
}

.delete-label-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem; 
}

.delete-item-div {
  position: relative;
  overflow: hidden;
  width: 100%;
  flex: 0 0 calc(50% - 1rem);
  max-width: calc(100% - 1rem);
}

@media (min-width: 576px) { /* Small devices*/
  .delete-item-div {
    flex: 0 0 calc(50% - 1rem); /* 2 items per row */
    max-width: calc(75% - 1rem);
  }
}

@media (min-width: 768px) { /* Medium devices */
  .delete-item-div {
    flex: 0 0 calc(50% - 1rem); /* 2 items per row */
  }

  .delete-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
  }
}

@media (min-width: 992px) { /* Large devices */
  .delete-item-div {
    flex: 0 0 calc(25% - 1rem); /* 4 items per row */
    max-width: calc(25% - 1rem);
  }
}

.delete-item {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  user-select: none;
}

.delete-item:hover {
  transform: scale(1.05);
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 15px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px;
  border-radius: 20%;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}

.delete-button:hover {
  background-color: rgba(255, 0, 0, 0.7);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading-container *{
  color: #850F8D !important;
}

.delete-pagination-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.delete-pagination {
  margin-top: 2rem;
  padding-bottom: 1.5rem;
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
}

.delete-pagination-inner {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.delete-pagination::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.delete-pagination::-webkit-scrollbar-thumb {
  background-color: #850F8D;
  border-radius: 5px;
}

.btn {
  min-width: 40px;
}

.btn-primary {
  background-color: #c34acc !important;
  border-color: #c34acc !important;
}

.btn-primary:hover,
.btn-primary:active
 {
  background-color: #a82f9c !important; /* Slightly darker shade for hover, focus, and active states */
  border-color: #a82f9c !important;
}

.btn-outline-primary {
  color: #c34acc !important;
  border-color: #c34acc !important;
}

.btn-outline-primary:hover,
.btn-outline-primary:active{
  color: #fff !important;
  background-color: #c34acc !important;
  border-color: #c34acc !important;
}


@media only screen and (max-width: 1500px) {
  .delete-main {
    margin-top: 0.3rem !important;
  }
}