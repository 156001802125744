@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins:wght@400;600&display=swap');

.container {
  max-width: 1200px;
  padding: 15px;
}

.logoImg {
  max-width: 100%;
  height: auto;
  transition: transform 0.2s ease-in-out;
  user-select: none;
}

.small-img {
  max-width: 80%;
  margin: 0 auto; 
}

.typewriter-text {
  min-height: 2em;
}

.typewriter2-text {
  min-height: 2em;
}

.abtHead {
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  font-size: 3.5rem;
  color: #850F8D;
}

.typical-text {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  color: #555;
}

.abtPara {
  margin-bottom: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.social-icons {
  display: flex;
  justify-content: start;
  gap: 20px; 
  margin-top: 20px;
}

.social-icons a {
  color: #000;
  transition: color 0.3s;
}

.social-icons a:hover{
  color:#850F8D;
  transform: scale(1.3);
  }
  
.footer {
  background-color: #f8f9fa;
  padding: 20px 0;
  text-align: center;
  background-color: #850F8D;
}

.footer p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  color: #ffff;
  margin: 5px 0; 
}

@keyframes popup {
  0% { transform: scale(0); opacity: 0; }
  80% { transform: scale(1.1); }
  100% { transform: scale(1); opacity: 1; }
}

.typewriter2-text {
  animation: popup 0.5s ease forwards;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .image-container {
    order: -1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logoImg{
    max-width: 70%;
  }

  .abtHead {
    font-size: 2rem;
  }

  .typical-text {
    text-align: center;
    font-size: 1.3rem;
  }

  .abtPara {
    font-size: 0.9rem;
  }
}