@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Oleo+Script:wght@400;700&family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Oleo+Script:wght@400;700&family=Pacifico&family=Teko:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia&display=swap');

.DashBoard-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    background-color: #F7F3F8;
}

.MainDashBoardDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 80%;
    gap: 20px;
    padding: 20px;
}

.count-div-main{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    align-self: center;
    margin-top: 10px;
    flex-direction: row;
    column-gap: 10%;
    margin-bottom: 50px;
}

.count-div-tabs {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
    padding: 20px;
}

.count-div-tabs:hover {
    box-shadow: 0 2px 8px 4px rgba(133, 15, 141, 0.4);
}

.count-div-tabs .dashboard-count {
    font-size: 3rem;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #850F8D;
}
.count-div-tabs p {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 1.4rem;
    background-color: transparent;
}

.count-icon {
    position: relative;
    width: 25%;
    height: 25%;
    background-color: transparent;
  }

.dashboard-upload-main {
    border-top: 2px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* padding-bottom: 30px; */
    /* margin-bottom: 24px; */
    border-bottom: 2px solid black;
}

.dashboard-head-texts {
    font-size: 40px;
    margin: 24px;
    text-align: center;
}

.dashboard-upload-main .dashboard-head-texts{
    margin-bottom: 0 !important;
}

.dashboard-delete-main {
    display: flex;
    margin-top: -24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}

.dashboard-delete-btns {
    display: flex;
    gap: 30px;
}

.dashboard-delete-btns * {
    display: flex;
    gap: 30px;
    text-decoration: none;
    background-color: #850F8D;
    border-radius: 5px;
    padding: 2px 10px 2px 10px;
    color: white;
    font-size: 20px;
}

/* piechart css */
.piechart-div-main {
    border-top: 2px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 !important;
    margin-bottom: 24px;
}

.piechart-div-sub {
    border: none !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
}

.piechart-div-sub:hover {
    box-shadow: 0 2px 8px 4px rgba(133, 15, 141, 0.4);
}

.piechart-div{
    width: 25%;
}

.storage-extra_details-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    word-break: break-word;
    overflow-wrap: break-word;
}


@media (max-width: 1500px) {
    .MainDashBoardDiv {
        width: 80%;
    }
    .count-div-main{
        width: 82%;
    }
    .piechart-div{
        width: 27%;
    }
    .lead{
        font-size: 1rem;
    }
}


@media (max-width: 1000px) {
    .MainDashBoardDiv {
        width: 90%;
    }
    .count-div-main{
        width: 82%;
    }
    .piechart-div{
        width: 40%;
    }
}


@media (max-width: 750px) {
    .count-div-main{
        flex-direction: column;
    }
    .count-div-tabs:first-child {
        margin-bottom: 15px;
    }
    .dashboard-head-texts {
        font-size: 27px;
    }
    .piechart-div-sub {
        flex-direction: column;
        justify-content: center;
    }
    .piechart-div{
        width: 50%;
    }
}

@media (max-width: 550px) {
    .piechart-div{
        width: 75%;
    }
}