.tab-bar {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content:center ;
  margin-top: 0%;
  list-style-type: none;
  padding-left: 0;
  position: relative;
}

.tab {
  width: 150px;
  padding-bottom: 10px;
  background: transparent;
  color: #607D8B;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.indicator {
  width: 150px;
  height: 5px;
  margin-left:-150px;
  padding-top: 10px;
  background: #850F8D;
  position: absolute;
  top: calc(100% - 3px);
  transition: transform 0.5s ease;
}

.tab1 {
  transform: translateX(0%);
}

.tab2 {
  transform: translateX(100%);
}

.content {
  flex-grow: 1;
  margin-top: 50px;
}

.light .cercle {
  background: #F7F3F8;
}

.dark .cercle {
  background: black;
}

.anim {
  opacity: 0.2;
  filter: alpha(opacity=20);
  animation: touch 1.2s ease-out;
}

@keyframes touch {
  100% { 
    width: 600px;
    height: 600px;
    border-radius: 600px;
    opacity: 0;
    filter: alpha(opacity=0);
    margin: -300px;
  }
}