.photo-container img {
    box-shadow: 10px;
    transition: border-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    user-select: none;
  }
  
  .photo-container {
    padding: 10px;
  }
  
.loading-viewMore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .loading {
    border: 5px solid #F7F3F8;
    border-top: 5px solid #850F8D;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
.image-video {
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.5);
  }
  
.image-video:hover {
    transform: scale(1.007); /* Zoom in on hover */
    transition: transform 0.3s ease; /* Add a smooth transition */
  }


  @media screen and (max-width: 768px) {
    .photo-container img {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: border-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    }   
  }