/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Oleo+Script:wght@400;700&family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Oleo+Script:wght@400;700&family=Pacifico&family=Teko:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia&display=swap');

body, html {
  margin: 0;
  padding: 0;
  /* overflow-x: hidden;  */
}

body {
  font-family: 'Poppins', sans-serif;
  color: #000000;
  background-color: #F7F3F8 !important;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-thumb {
  background: #850F8D;
  border-radius: 5px;
}

*::selection
{
  background-color: #850F8D;
  color: #ffffff;
}

/* success toast progress bar color */
.toast-success-progress-bar {
  background-color: #850F8D !important;
}

/* navigation bar */
.header-Main {
  display: flex; /* Ensure children are displayed inline */
  flex-direction: row; /* Ensure the direction is row */
  align-items: center; /* Align items vertically in the center */
  justify-content: space-between; /* Space out items evenly */
  padding: 0;
  margin-top: 10px;
  position: relative; /* Add position relative to parent div */
}

/* brandName div in navigation */
.heading-container {
  display:flex;
  position: relative;
  left:1%;
  padding: 0;
  margin: 0;
  cursor : default;
}

.header-gradient-text {
  background: linear-gradient(to right, black, #c34acc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: inline-block;
  padding: 0 0 7px 3px;
}

/* contact div in navigation */
.header-links {
  display:flex;
  position: relative;
  right: 1%;
  padding: 0;
  margin: 0;
}

.header-links .contact-link {
  margin-left: 23px;
}

/* back button of all pages, let it be in this file itself */
.back-btn :first-child{
  color: #000000;
  text-decoration: none;
  font-size: 2rem;
  margin-left: 10px;
  border-radius: 5px;
  transition: color 0.2s ease-in-out, transform 0.1s ease-in-out;
}

.back-btn :first-child:hover{
  color: #850F8D;
}

/*css for the brandName text*/
.sofia-regular {
  font-family: "Sofia", cursive;
  font-weight: 400;
  font-style: normal;
  margin: 0%;
  font-size: 40px;
  color: #000000;
  position: relative; /* Required for positioning the signIn div */
}

/*css for the Contact text*/
.teko-headings {
  color: #000000;
  text-decoration: none;
  background-color: transparent;
  font-size: 23px;
  margin:0%;
  border-bottom: 2px solid #F7F3F8;
  transition: border-bottom 0.5s ease;
}

.teko-headings:hover {
  border-bottom: 2px solid #850F8D;
}

/*css of main div*/
.Main-div {
  display: flex; /* Ensure children are displayed inline */
  flex-direction: column; /* Ensure the direction is row */
  column-gap: 10px;
  padding: .1%;
}

/*css of homePage-textBox*/
.homePage-textBox {
  display: flex;
  position: relative;
  justify-content: center;
  text-align: center;
}

.homePage-textBox p {
  width: 60%;
  margin-top: 50px;
  color: #000000;
  opacity: 0; /* Initial opacity */
  font-size: 18px;
  animation: fadeIn 2.5s ease-in-out forwards; /* Apply animation */
}

/* Keyframes for fadeIn animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*css for signIn div */
.signIn {
  width: 0;
  height: 0;
  overflow-x: hidden;
  overflow-y: hidden; /* needed */
  background-color: #F7F3F8;
  position: absolute;
  display: flex; /* Ensure children are displayed inline */
  flex-direction: column;
  padding: 10px;
  left: 15px;
  border-radius: 5%;
  top: 9px; /* Align to the left of the heading-container */
  z-index: 15; /* Ensure it is above all other content */
  visibility: hidden;
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out, visibility 0s 0.5s;
}

.signIn.visible {
  visibility: visible;
  width: 400px;
  height: 330px;
  box-shadow: 0 4px 10px 5px rgba(0, 0, 0, 0.397);
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out, visibility 0s 0s;
}

/*css of the close div of the signin div*/
.close-div{
  display: flex; /* Ensure children are displayed inline */
  flex-direction: row; /* Ensure the direction is row */
  position: relative;
  justify-content: right;
}

/* css of the admin login text in signin div */
.signIn-text{
  font-size: 24px;
  font-family:sans-serif;
  font-weight: bolder;
  color: #000000;
}

/*css of the close button of the signin div*/
.close-SignIn{
  color: grey;
  position: relative;
  font-size: 40px;
  cursor: pointer;
}
.close-SignIn:hover{
  color: #cf2f2f;
}

@media only screen and (max-width: 768px) {
  /* back button of all pages, let it be in this file itself */
  .back-btn :first-child{
    margin-left: 3px;
    font-size: 1.5rem;
  }

  .sofia-regular {
    font-size: 20px;
  }
  .teko-headings {
    font-size: 11.5px;
  }
  .homePage-textBox p {
    font-size: 9px;
    width: 80%;
    margin-top: 35px;
  }
  .homePage-textBox p h4 {
    font-size: 12px;
  }
  .header-links .contact-link {
    margin-left: 10px;
    margin-right: 5px;
  }
    
  .signIn.visible {
    width: 60%; /* Make it responsive to the screen size */
    height: 330px; /* Adjust height if needed */
  }

  .close-div {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the start */
    justify-content: flex-start; /* Start items at the beginning */
  }

  .signIn-text {
    font-size: 20px; /* Adjust font size */
    text-align: center; /* Center text */
  }

  .close-SignIn {
    font-size: 30px; /* Adjust font size */
    align-self: flex-end; /* Align the close button to the end */
  }
}

/* css of admin form */
.admin-form{
  display: flex; /* Ensure children are displayed inline */
  flex-direction:column;
  justify-content: center;
  text-align: center;
  gap: 10px;
  position: relative;
}
.admin-form form{
  display: flex; /* Ensure children are displayed inline */
  flex-direction:column;
  justify-content: center;
  gap: 10px;
  
}

.admin-form input {
  height: 50px;
  width: 100%;
  position: relative;
  border: none;
  background-color: inherit;
  border-bottom: 2px solid #850F8D;
  padding-left: 10px;
}

/* Optional: Adding focus styles to remove any default outline and add a custom focus effect */
.admin-form input:focus {
  outline: none; /* Remove default focus outline */
}

.admin-email{
  position: relative;
}

.admin-submit{
  height: 50px;
  width: 100%;
  margin-top: 20px;
  position: relative;
  border-color:#F7F3F8;
  background-color: #850F8D;
  color: rgb(255, 255, 255);
  border-radius: 5px;
}

.admin-ForgotPass{
  background-color: transparent;
  border: none;
  color: #650f6b;
}

.logout-btn{
  height: 50px;
  width: 100%;
  margin-top: 100px;
  position: relative;
  border-color:#F7F3F8;
  background-color: #850F8D;
  color: rgb(255, 255, 255);
  border-radius: 5px;
}

/* admin form Responsive styles for screens 768px and smaller */
@media (max-width: 768px) {
  .admin-form {
    padding: 10px; /* Add padding for better spacing */
  }

  .admin-form input, 
  .admin-submit,
  .logout-btn {
    width: 100%; /* Make inputs and button full width */
    left: 0; /* Remove relative left positioning */
  }

  .admin-ForgotPass {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

/* Scroll to top floating btn section */
.scroll-to-top-float-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #850F8D;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  z-index: 8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.scroll-to-top-float-btn:hover {
  background-color: #850F8D;
  color: #ffff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Scroll to top when end reached section */
.scroll-to-top-end-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 35px;
}

.scroll-to-top-end-text {
  width: 20%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 13px;
}

.scroll-to-top-end-btn {
  background-color: #F7F3F8;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  padding: 1px 10px 1px 10px;
  border-bottom: 2px solid #850F8D;
  transition: background-color 0.3s, color 0.3s, border-bottom 0.3s;
}

.scroll-to-top-end-btn:hover{
  background-color: #850F8D;
  color: rgb(255, 255, 255);
  border-bottom: 2px solid #000000;
}

@media (max-width: 1200px) {
  .scroll-to-top-end-text {
    width: 30%;
  }
}

@media (max-width: 850px) {
  .scroll-to-top-end-text {
    width: 60%;
  }
}

@media (max-width: 500px) {
  .scroll-to-top-end-text {
    width: 80%;
  }
}